<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    right
    width="350"
    temporary
    class="b-notification"
    v-resize="resize"
  >
    <vue-perfect-scrollbar
      :settings="scrollSettings"
      :style="`max-height: ${innerHeight}px`"
    >
      <h5>Notificações</h5>

      <v-tabs
        v-model="tab"
        color="primary"
        background-color="transparent"
        class="b-notification--tab"
      >
        <v-tab href="#tab-1">
          <v-badge dot class="badge-notify" color="red" :value="countWarning">
            <span>Alertas</span>
          </v-badge>
        </v-tab>
        <v-tab href="#tab-2">
          <v-badge dot class="badge-notify" color="red" :value="countTask">
            <span>Tarefas</span>
          </v-badge>
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <Tab type="Alertas" :items="notifications.warningNotifications" />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <Tab type="Tarefas" :items="notifications.taskNotifications" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    VuePerfectScrollbar: () => import('vue-perfect-scrollbar'),
    Tab: () => import('./tab')
  },

  data: () => ({
    tab: 'tab-1',
    scrollSettings: { maxScrollbarLength: 50, suppressScrollX: true },
    innerHeight: 0
  }),

  computed: {
    drawer: {
      get() {
        return this.$store.getters['notification/openOrClosed'];
      },
      set(val) {
        if (val) {
          return val;
        } else {
          this.$store.commit('notification/openOrClosed');
        }
      }
    },

    ...mapGetters({
      notifications: 'notification/getNotifications'
    }),

    countWarning() {
      const messsages = this.notifications.warningNotifications.filter(
        x => x.read === false
      );

      return messsages.length;
    },

    countTask() {
      const messsages = this.notifications.taskNotifications.filter(
        x => x.read === false
      );

      return messsages.length;
    },

    countTotal() {
      return this.countWarning + this.countTask;
    }
  },

  mounted() {
    this.resize();
  },

  methods: {
    resize() {
      this.innerHeight = window.innerHeight - 35;
    }
  }
};
</script>
